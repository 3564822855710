
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexZWkKEH_4532GKvrpAN6UX8msW8KhS4a1vZSZqRRrRhHdIMeta } from "/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/pages/index.vue?macro=true";
import { default as indexklOXz2R_Qz1OMiAt5s3HGEbNYCgrosXXfaVTZqWx0hwMeta } from "/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/pages/[[lang]]/index.vue?macro=true";
import { default as start5UYuOEJxLOZFPKUnKD2vh9P4PR47ZiftpvD_CBvkY40Meta } from "/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/pages/[[lang]]/voucher/start.vue?macro=true";
import { default as start823gF_4519p7qUMgp746zbJ2vV_qfCwp8MN_45S_45bWJ9TtYMeta } from "/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/pages/[[lang]]/purchase/start.vue?macro=true";
import { default as checkoutLvXftawpuHAYlql9VlAO_gynoMgCa6Heb3mKKWieovEMeta } from "/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/pages/[[lang]]/service-booking/checkout.vue?macro=true";
import { default as koyHFfDBTX023MAOt0n7TDbeb_45_45H5kWTqGVWum8bjf9uoMeta } from "/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue?macro=true";
import { default as confirmationiXVC39mTGfY5BwP6CpAtEun5krkwkeOIaMR0_qixm6cMeta } from "/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue?macro=true";
import { default as confirmation_45iDyMRIamaIa0mStYN1YmWfaaogDYv7Wn59WNSaVfc8Meta } from "/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue?macro=true";
import { default as buyerZTQ8vLCCOFwbXdSLTJio7C6_458PNNd8hGQKHCCjCGOYwMeta } from "/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as buyerTZ0iMJBbEUstObbFZ97XzUj9v6b_45rQPeyOfFUcFzD8gMeta } from "/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as paymentrldUrCOozTKstrHYB_sdpyCI9UkWQYVVwAB4bu7sSbMMeta } from "/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue?macro=true";
import { default as challengeIBhAgEyMCfpqM01o764rHSZZ6J_OtIpkDl5qp2oUIRYMeta } from "/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue?macro=true";
import { default as paymentoDO_45UKcQ_45XLhcqBBixR4QNjEYY500nQHmhljk7LYrcUMeta } from "/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue?macro=true";
import { default as SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta } from "/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/spa/SpaList.page.vue?macro=true";
import { default as SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta } from "/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/spa/SpaDetail.page.vue?macro=true";
import { default as ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta } from "/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue?macro=true";
export default [
  {
    name: indexZWkKEH_4532GKvrpAN6UX8msW8KhS4a1vZSZqRRrRhHdIMeta?.name ?? "index",
    path: indexZWkKEH_4532GKvrpAN6UX8msW8KhS4a1vZSZqRRrRhHdIMeta?.path ?? "/",
    props: indexZWkKEH_4532GKvrpAN6UX8msW8KhS4a1vZSZqRRrRhHdIMeta?.props ?? false,
    meta: indexZWkKEH_4532GKvrpAN6UX8msW8KhS4a1vZSZqRRrRhHdIMeta || {},
    alias: indexZWkKEH_4532GKvrpAN6UX8msW8KhS4a1vZSZqRRrRhHdIMeta?.alias || [],
    redirect: indexZWkKEH_4532GKvrpAN6UX8msW8KhS4a1vZSZqRRrRhHdIMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/pages/index.vue")
  },
  {
    name: indexklOXz2R_Qz1OMiAt5s3HGEbNYCgrosXXfaVTZqWx0hwMeta?.name ?? "lang",
    path: indexklOXz2R_Qz1OMiAt5s3HGEbNYCgrosXXfaVTZqWx0hwMeta?.path ?? "/:lang?",
    props: indexklOXz2R_Qz1OMiAt5s3HGEbNYCgrosXXfaVTZqWx0hwMeta?.props ?? false,
    meta: indexklOXz2R_Qz1OMiAt5s3HGEbNYCgrosXXfaVTZqWx0hwMeta || {},
    alias: indexklOXz2R_Qz1OMiAt5s3HGEbNYCgrosXXfaVTZqWx0hwMeta?.alias || [],
    redirect: indexklOXz2R_Qz1OMiAt5s3HGEbNYCgrosXXfaVTZqWx0hwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/pages/[[lang]]/index.vue")
  },
  {
    name: start5UYuOEJxLOZFPKUnKD2vh9P4PR47ZiftpvD_CBvkY40Meta?.name ?? "lang-voucher-start",
    path: start5UYuOEJxLOZFPKUnKD2vh9P4PR47ZiftpvD_CBvkY40Meta?.path ?? "/:lang?/voucher/start",
    props: start5UYuOEJxLOZFPKUnKD2vh9P4PR47ZiftpvD_CBvkY40Meta?.props ?? false,
    meta: start5UYuOEJxLOZFPKUnKD2vh9P4PR47ZiftpvD_CBvkY40Meta || {},
    alias: start5UYuOEJxLOZFPKUnKD2vh9P4PR47ZiftpvD_CBvkY40Meta?.alias || [],
    redirect: start5UYuOEJxLOZFPKUnKD2vh9P4PR47ZiftpvD_CBvkY40Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/pages/[[lang]]/voucher/start.vue")
  },
  {
    name: start823gF_4519p7qUMgp746zbJ2vV_qfCwp8MN_45S_45bWJ9TtYMeta?.name ?? "lang-purchase-start",
    path: start823gF_4519p7qUMgp746zbJ2vV_qfCwp8MN_45S_45bWJ9TtYMeta?.path ?? "/:lang?/purchase/start",
    props: start823gF_4519p7qUMgp746zbJ2vV_qfCwp8MN_45S_45bWJ9TtYMeta?.props ?? false,
    meta: start823gF_4519p7qUMgp746zbJ2vV_qfCwp8MN_45S_45bWJ9TtYMeta || {},
    alias: start823gF_4519p7qUMgp746zbJ2vV_qfCwp8MN_45S_45bWJ9TtYMeta?.alias || [],
    redirect: start823gF_4519p7qUMgp746zbJ2vV_qfCwp8MN_45S_45bWJ9TtYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/pages/[[lang]]/purchase/start.vue")
  },
  {
    name: checkoutLvXftawpuHAYlql9VlAO_gynoMgCa6Heb3mKKWieovEMeta?.name ?? "lang-service-booking-checkout",
    path: checkoutLvXftawpuHAYlql9VlAO_gynoMgCa6Heb3mKKWieovEMeta?.path ?? "/:lang?/service-booking/checkout",
    props: checkoutLvXftawpuHAYlql9VlAO_gynoMgCa6Heb3mKKWieovEMeta?.props ?? false,
    meta: checkoutLvXftawpuHAYlql9VlAO_gynoMgCa6Heb3mKKWieovEMeta || {},
    alias: checkoutLvXftawpuHAYlql9VlAO_gynoMgCa6Heb3mKKWieovEMeta?.alias || [],
    redirect: checkoutLvXftawpuHAYlql9VlAO_gynoMgCa6Heb3mKKWieovEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/pages/[[lang]]/service-booking/checkout.vue")
  },
  {
    name: koyHFfDBTX023MAOt0n7TDbeb_45_45H5kWTqGVWum8bjf9uoMeta?.name ?? "lang-purchase-packagepurchaseuuid-ko",
    path: koyHFfDBTX023MAOt0n7TDbeb_45_45H5kWTqGVWum8bjf9uoMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/ko",
    props: koyHFfDBTX023MAOt0n7TDbeb_45_45H5kWTqGVWum8bjf9uoMeta?.props ?? false,
    meta: koyHFfDBTX023MAOt0n7TDbeb_45_45H5kWTqGVWum8bjf9uoMeta || {},
    alias: koyHFfDBTX023MAOt0n7TDbeb_45_45H5kWTqGVWum8bjf9uoMeta?.alias || [],
    redirect: koyHFfDBTX023MAOt0n7TDbeb_45_45H5kWTqGVWum8bjf9uoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue")
  },
  {
    name: confirmationiXVC39mTGfY5BwP6CpAtEun5krkwkeOIaMR0_qixm6cMeta?.name ?? "lang-voucher-voucherpurchaseuuid-confirmation",
    path: confirmationiXVC39mTGfY5BwP6CpAtEun5krkwkeOIaMR0_qixm6cMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/confirmation",
    props: confirmationiXVC39mTGfY5BwP6CpAtEun5krkwkeOIaMR0_qixm6cMeta?.props ?? false,
    meta: confirmationiXVC39mTGfY5BwP6CpAtEun5krkwkeOIaMR0_qixm6cMeta || {},
    alias: confirmationiXVC39mTGfY5BwP6CpAtEun5krkwkeOIaMR0_qixm6cMeta?.alias || [],
    redirect: confirmationiXVC39mTGfY5BwP6CpAtEun5krkwkeOIaMR0_qixm6cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue")
  },
  {
    name: confirmation_45iDyMRIamaIa0mStYN1YmWfaaogDYv7Wn59WNSaVfc8Meta?.name ?? "lang-purchase-packagepurchaseuuid-confirmation",
    path: confirmation_45iDyMRIamaIa0mStYN1YmWfaaogDYv7Wn59WNSaVfc8Meta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/confirmation",
    props: confirmation_45iDyMRIamaIa0mStYN1YmWfaaogDYv7Wn59WNSaVfc8Meta?.props ?? false,
    meta: confirmation_45iDyMRIamaIa0mStYN1YmWfaaogDYv7Wn59WNSaVfc8Meta || {},
    alias: confirmation_45iDyMRIamaIa0mStYN1YmWfaaogDYv7Wn59WNSaVfc8Meta?.alias || [],
    redirect: confirmation_45iDyMRIamaIa0mStYN1YmWfaaogDYv7Wn59WNSaVfc8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue")
  },
  {
    name: buyerZTQ8vLCCOFwbXdSLTJio7C6_458PNNd8hGQKHCCjCGOYwMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-buyer",
    path: buyerZTQ8vLCCOFwbXdSLTJio7C6_458PNNd8hGQKHCCjCGOYwMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/buyer",
    props: buyerZTQ8vLCCOFwbXdSLTJio7C6_458PNNd8hGQKHCCjCGOYwMeta?.props ?? false,
    meta: buyerZTQ8vLCCOFwbXdSLTJio7C6_458PNNd8hGQKHCCjCGOYwMeta || {},
    alias: buyerZTQ8vLCCOFwbXdSLTJio7C6_458PNNd8hGQKHCCjCGOYwMeta?.alias || [],
    redirect: buyerZTQ8vLCCOFwbXdSLTJio7C6_458PNNd8hGQKHCCjCGOYwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: buyerTZ0iMJBbEUstObbFZ97XzUj9v6b_45rQPeyOfFUcFzD8gMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-buyer",
    path: buyerTZ0iMJBbEUstObbFZ97XzUj9v6b_45rQPeyOfFUcFzD8gMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/buyer",
    props: buyerTZ0iMJBbEUstObbFZ97XzUj9v6b_45rQPeyOfFUcFzD8gMeta?.props ?? false,
    meta: buyerTZ0iMJBbEUstObbFZ97XzUj9v6b_45rQPeyOfFUcFzD8gMeta || {},
    alias: buyerTZ0iMJBbEUstObbFZ97XzUj9v6b_45rQPeyOfFUcFzD8gMeta?.alias || [],
    redirect: buyerTZ0iMJBbEUstObbFZ97XzUj9v6b_45rQPeyOfFUcFzD8gMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: paymentrldUrCOozTKstrHYB_sdpyCI9UkWQYVVwAB4bu7sSbMMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-payment",
    path: paymentrldUrCOozTKstrHYB_sdpyCI9UkWQYVVwAB4bu7sSbMMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/payment",
    props: paymentrldUrCOozTKstrHYB_sdpyCI9UkWQYVVwAB4bu7sSbMMeta?.props ?? false,
    meta: paymentrldUrCOozTKstrHYB_sdpyCI9UkWQYVVwAB4bu7sSbMMeta || {},
    alias: paymentrldUrCOozTKstrHYB_sdpyCI9UkWQYVVwAB4bu7sSbMMeta?.alias || [],
    redirect: paymentrldUrCOozTKstrHYB_sdpyCI9UkWQYVVwAB4bu7sSbMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue")
  },
  {
    name: challengeIBhAgEyMCfpqM01o764rHSZZ6J_OtIpkDl5qp2oUIRYMeta?.name ?? "lang-voucher-voucherpurchaseuuid-redsys-challenge",
    path: challengeIBhAgEyMCfpqM01o764rHSZZ6J_OtIpkDl5qp2oUIRYMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/redsys/challenge",
    props: challengeIBhAgEyMCfpqM01o764rHSZZ6J_OtIpkDl5qp2oUIRYMeta?.props ?? false,
    meta: challengeIBhAgEyMCfpqM01o764rHSZZ6J_OtIpkDl5qp2oUIRYMeta || {},
    alias: challengeIBhAgEyMCfpqM01o764rHSZZ6J_OtIpkDl5qp2oUIRYMeta?.alias || [],
    redirect: challengeIBhAgEyMCfpqM01o764rHSZZ6J_OtIpkDl5qp2oUIRYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue")
  },
  {
    name: paymentoDO_45UKcQ_45XLhcqBBixR4QNjEYY500nQHmhljk7LYrcUMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-payment",
    path: paymentoDO_45UKcQ_45XLhcqBBixR4QNjEYY500nQHmhljk7LYrcUMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/payment",
    props: paymentoDO_45UKcQ_45XLhcqBBixR4QNjEYY500nQHmhljk7LYrcUMeta?.props ?? false,
    meta: paymentoDO_45UKcQ_45XLhcqBBixR4QNjEYY500nQHmhljk7LYrcUMeta || {},
    alias: paymentoDO_45UKcQ_45XLhcqBBixR4QNjEYY500nQHmhljk7LYrcUMeta?.alias || [],
    redirect: paymentoDO_45UKcQ_45XLhcqBBixR4QNjEYY500nQHmhljk7LYrcUMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue")
  },
  {
    name: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.name ?? "Spa by location landing: 0",
    path: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.path ?? "/spa-balneario/",
    props: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.props ?? false,
    meta: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta || {},
    alias: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.alias || [],
    redirect: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.name ?? "Spa by location landing: 1",
    path: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.path ?? "/spa-balneario/granada/",
    props: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.props ?? false,
    meta: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta || {},
    alias: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.alias || [],
    redirect: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.name ?? "Spa by location landing: 2",
    path: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.path ?? "/spa-balneario/granada/almunecar/",
    props: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.props ?? false,
    meta: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta || {},
    alias: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.alias || [],
    redirect: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.name ?? "Spa by location landing: 3",
    path: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.path ?? "/spa-balneario/granada/alpujarra-de-la-sierra/",
    props: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.props ?? false,
    meta: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta || {},
    alias: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.alias || [],
    redirect: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.name ?? "Spa by location landing: 4",
    path: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.path ?? "/spa-balneario/granada/granada/",
    props: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.props ?? false,
    meta: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta || {},
    alias: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.alias || [],
    redirect: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.name ?? "Spa by location landing: 5",
    path: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.path ?? "/spa-balneario/granada/lanjaron/",
    props: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.props ?? false,
    meta: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta || {},
    alias: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.alias || [],
    redirect: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.name ?? "Spa by location landing: 6",
    path: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.path ?? "/spa-balneario/granada/loja/",
    props: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.props ?? false,
    meta: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta || {},
    alias: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.alias || [],
    redirect: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.name ?? "Spa by location landing: 7",
    path: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.path ?? "/spa-balneario/granada/monachil/",
    props: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.props ?? false,
    meta: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta || {},
    alias: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.alias || [],
    redirect: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.name ?? "Spa by location landing: 8",
    path: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.path ?? "/spa-balneario/granada/motril/",
    props: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.props ?? false,
    meta: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta || {},
    alias: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.alias || [],
    redirect: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.name ?? "Spa by location landing: 9",
    path: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.path ?? "/spa-balneario/granada/niguelas/",
    props: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.props ?? false,
    meta: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta || {},
    alias: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.alias || [],
    redirect: SpaList_46pageKv1bys5wEJo5MuhE4g0GRqfiL3opOI7aIOSptbfpDIsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.name ?? "Spa landing: 0",
    path: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/",
    props: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.props ?? false,
    meta: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta || {},
    alias: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.alias || [],
    redirect: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.name ?? "Spa landing: 1",
    path: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/",
    props: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.props ?? false,
    meta: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta || {},
    alias: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.alias || [],
    redirect: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.name ?? "Spa landing: 2",
    path: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.path ?? "/spa-balneario/granada/spa-wellness-el-cercado/",
    props: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.props ?? false,
    meta: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta || {},
    alias: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.alias || [],
    redirect: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.name ?? "Spa landing: 3",
    path: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.path ?? "/spa-balneario/granada/beer-spa/",
    props: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.props ?? false,
    meta: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta || {},
    alias: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.alias || [],
    redirect: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.name ?? "Spa landing: 4",
    path: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.path ?? "/spa-balneario/granada/spa-playa-granada/",
    props: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.props ?? false,
    meta: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta || {},
    alias: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.alias || [],
    redirect: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.name ?? "Spa landing: 5",
    path: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/",
    props: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.props ?? false,
    meta: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta || {},
    alias: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.alias || [],
    redirect: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.name ?? "Spa landing: 6",
    path: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.path ?? "/spa-balneario/granada/relais-termal-balneario-lanjaron/",
    props: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.props ?? false,
    meta: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta || {},
    alias: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.alias || [],
    redirect: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.name ?? "Spa landing: 7",
    path: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.path ?? "/spa-balneario/granada/alqueria-de-los-lentos/",
    props: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.props ?? false,
    meta: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta || {},
    alias: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.alias || [],
    redirect: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.name ?? "Spa landing: 8",
    path: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.path ?? "/spa-balneario/granada/spa-yhi-wellness-melia-sierra-nevada/",
    props: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.props ?? false,
    meta: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta || {},
    alias: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.alias || [],
    redirect: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.name ?? "Spa landing: 9",
    path: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.path ?? "/spa-balneario/granada/al-haram-granada-banos-de-santa-paula/",
    props: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.props ?? false,
    meta: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta || {},
    alias: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.alias || [],
    redirect: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.name ?? "Spa landing: 10",
    path: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.path ?? "/spa-balneario/granada/musg-belleza-y-agua/",
    props: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.props ?? false,
    meta: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta || {},
    alias: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.alias || [],
    redirect: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.name ?? "Spa landing: 11",
    path: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/",
    props: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.props ?? false,
    meta: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta || {},
    alias: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.alias || [],
    redirect: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.name ?? "Spa landing: 12",
    path: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/",
    props: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.props ?? false,
    meta: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta || {},
    alias: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.alias || [],
    redirect: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.name ?? "Spa landing: 13",
    path: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/",
    props: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.props ?? false,
    meta: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta || {},
    alias: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.alias || [],
    redirect: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.name ?? "Spa landing: 14",
    path: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.path ?? "/spa-balneario/granada/spa-seda-club/",
    props: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.props ?? false,
    meta: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta || {},
    alias: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.alias || [],
    redirect: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.name ?? "Spa landing: 15",
    path: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.path ?? "/spa-balneario/granada/health-club-ghm-monachil/",
    props: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.props ?? false,
    meta: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta || {},
    alias: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.alias || [],
    redirect: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.name ?? "Spa landing: 16",
    path: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.path ?? "/spa-balneario/granada/spa-hotel-el-mirador/",
    props: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.props ?? false,
    meta: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta || {},
    alias: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.alias || [],
    redirect: SpaDetail_46page9zBZwFqLatXu660wa1xefC8LgOekG39YuLVXtUhypVIMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 0",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/especial-pareja-circuito-arabe-masaje-de-15-min-servicio-de-te-parejas/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 1",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/especial-parejas-circuito-arabe-masaje-de-55-min-servicio-de-te-parejas/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 2",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/especial-pareja-circuito-arabe-servicio-de-te-parejas/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 3",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/especial-pareja-circuito-arabe-masaje-de-25-min-servicio-de-te-parejas/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 4",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-aromatico-parcial-25/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 5",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-aromatico-completo-55/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 6",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/ritual-al-andalus/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 7",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-exclusivity-completo-55/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 8",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/circuito-califa/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 9",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-esencias-naturales-completo-55/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 10",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/ritual-de-chocoterapia/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 11",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/circuito-arabe/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 12",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/ritual-gold-theraphy/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 13",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-exclusivity-parcial-25/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 14",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-exclusivity-completo-55/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 15",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-aromatico-completo-55/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 16",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/peeling-corporal-de-coco-hidratacion-completa/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 17",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/especial-pareja-circuito-dusch-masaje-de-25-minutos-servicio-de-te-parejas/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 18",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/especial-pareja-circuito-dusch-servicio-de-te-parejas/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 19",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/especial-pareja-circuito-dusch-masaje-de-15-minutos-servicio-de-te-parejas/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 20",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/circuito-dusch/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 21",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-de-pindas-herbales-60/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 22",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-wellness-el-cercado/spa-menu-para-2/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 23",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-wellness-el-cercado/spa-privado-y-masaje-para-2/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 24",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-wellness-el-cercado/spa-privado-para-2/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 25",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-wellness-el-cercado/spa-para-2/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 26",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-wellness-el-cercado/envoltura-facial-y-corporal-chocolate/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 27",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-wellness-el-cercado/masaje-completo/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 28",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-wellness-el-cercado/masaje-total/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 29",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-wellness-el-cercado/masaje-geotermal/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 30",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-wellness-el-cercado/masaje-con-canas-de-bambu/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 31",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-wellness-el-cercado/masaje-hawaiano-lomi-lomi/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 32",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-wellness-el-cercado/masaje-californiano/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 33",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-wellness-el-cercado/masaje-ayurvedico-con-pindas-de-hierba/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 34",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-wellness-el-cercado/spa-y-masaje-para-2/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 35",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-wellness-el-cercado/masaje-localizado/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 36",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/beer-spa/masaje-exclusivity-de-cerveza-55min/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 37",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/beer-spa/circuito-beer-spa-masaje-completo-55-min-sala-comun-pareja/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 38",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/beer-spa/masaje-exclusivity-de-cerveza-25min-pareja/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 39",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/beer-spa/circuito-beer-spa-en-sala-comun/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 40",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/beer-spa/circuito-beer-spa-masaje-15-minutos-sala-comun-1-persona/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 41",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/beer-spa/circuito-beer-spa-estudiantes/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 42",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/beer-spa/circuito-beer-spa-masaje-15-minutos-sala-comun-pareja/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 43",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/beer-spa/circuito-beer-spa-con-cata-y-maridaje/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 44",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/beer-spa/circuito-beer-spa-masaje-completo-de-75min-con-aceite-de-cerveza-pareja/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 45",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/beer-spa/circuito-beer-spa-masaje-localizado-25min-sala-comun-pareja/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 46",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/beer-spa/circuito-beer-spa-en-sala-comun-pareja/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 47",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/beer-spa/circuito-premium-en-sala-privada-pareja/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 48",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/beer-spa/circuito-vip-en-sala-privada-pareja/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 49",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-playa-granada/ritual-chocoterapia/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 50",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-playa-granada/masaje-aromatico-parcial-25/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 51",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-playa-granada/masaje-aromatico-completo-55/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 52",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-playa-granada/especial-pareja-circuito-playa-granada-servicio-de-te-parejas/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 53",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-playa-granada/especial-parejas-circuito-playa-granada-masaje-de-15-min-cena-2-personas/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 54",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-playa-granada/circuito-playa-granada/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 55",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-playa-granada/especial-pareja-circuito-playa-granada-masaje-de-15-min-servicio-de-te-parejas/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 56",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-playa-granada/especial-parejas-circuito-playa-granada-masaje-de-55-min-servicio-de-te-parejas/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 57",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-playa-granada/especial-pareja-circuito-playa-granada-masaje-de-25-min-servicio-de-te-parejas/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 58",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-playa-granada/circuito-motril/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 59",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-playa-granada/especial-parejas-circuito-playa-granada-masaje-de-25-min-cena-2-personas/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 60",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-playa-granada/masaje-esencias-naturales-parcial-25/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 61",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-playa-granada/masaje-esencias-naturales-completo-55/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 62",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-playa-granada/masaje-exclusivity-completo-55/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 63",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/viaje-a-la-ruta-de-las-delicias-circuito/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 64",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/relais-termal-balneario-lanjaron/circuito-aquatherma/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 65",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/relais-termal-balneario-lanjaron/circuito-aquatherma-con-comida-cena/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 66",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/alqueria-de-los-lentos/masaje-personalizado-para-2-personas/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 67",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/alqueria-de-los-lentos/pack-romantico-2-noches/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 68",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/alqueria-de-los-lentos/pack-romantico-1-noche/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 69",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/alqueria-de-los-lentos/jacuzzi-masaje-para-2/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 70",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/alqueria-de-los-lentos/jacuzzi-almuerzo-o-cena-para-2/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 71",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/alqueria-de-los-lentos/jacuzzi-para-2/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 72",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/alqueria-de-los-lentos/hammam/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 73",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-yhi-wellness-melia-sierra-nevada/jacuzzi-privado-con-moet-chandon/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 74",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-yhi-wellness-melia-sierra-nevada/yhi-indian-scalp/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 75",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-yhi-wellness-melia-sierra-nevada/aromaterapia/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 76",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-yhi-wellness-melia-sierra-nevada/bamboo-experience/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 77",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-yhi-wellness-melia-sierra-nevada/masaje-yhi-abhyanga/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 78",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-yhi-wellness-melia-sierra-nevada/jacuzzi-privado/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 79",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-yhi-wellness-melia-sierra-nevada/paquete-para-ella/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 80",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-yhi-wellness-melia-sierra-nevada/paquete-para-el/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 81",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-yhi-wellness-melia-sierra-nevada/escapada-romantica/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 82",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-yhi-wellness-melia-sierra-nevada/masaje-muscular-profundo/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 83",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-yhi-wellness-melia-sierra-nevada/tratamiento-facial-express/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 84",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-yhi-wellness-melia-sierra-nevada/tratamiento-facial-vitamina-c/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 85",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-yhi-wellness-melia-sierra-nevada/ritual-mediterraneo/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 86",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-yhi-wellness-melia-sierra-nevada/area-de-hidroterapia/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 87",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/al-haram-granada-banos-de-santa-paula/ritual-abu-said/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 88",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/al-haram-granada-banos-de-santa-paula/ritual-granada/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 89",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/al-haram-granada-banos-de-santa-paula/ritual-morayma/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 90",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/al-haram-granada-banos-de-santa-paula/ritual-farasha/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 91",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/al-haram-granada-banos-de-santa-paula/ritual-aixa/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 92",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/al-haram-granada-banos-de-santa-paula/ritual-boabdil/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 93",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/al-haram-granada-banos-de-santa-paula/bano-basico-masaje-de-30/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 94",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/al-haram-granada-banos-de-santa-paula/bano-basico-masaje-de-15/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 95",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/musg-belleza-y-agua/friends-beauty-party-grupal-6-personas/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 96",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/musg-belleza-y-agua/luxury-exclusive-pack-grupal-6-personas/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 97",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/musg-belleza-y-agua/circuito-spa-masaje-50-min-individual/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 98",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/musg-belleza-y-agua/duo-luxury-love-con-masaje-50-min-pareja/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 99",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/musg-belleza-y-agua/especial-celebracion-grupal-6-personas/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 100",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/musg-belleza-y-agua/circuito-spa-masaje-grupal-6-personas/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 101",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/musg-belleza-y-agua/circuito-spa-masaje-30-min-individual/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 102",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/musg-belleza-y-agua/duo-luxury-love-con-masaje-30-min-pareja/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 103",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/musg-belleza-y-agua/especial-celebracion-grupal-8-personas/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 104",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/musg-belleza-y-agua/circuito-spa-masaje-30-min-pareja/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 105",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/musg-belleza-y-agua/circuito-spa-masaje-50-min-pareja/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 106",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/musg-belleza-y-agua/circuito-spa-individual/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 107",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/musg-belleza-y-agua/circuito-spa-grupal-6-personas/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 108",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/musg-belleza-y-agua/circuito-spa-pareja/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 109",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/masaje-relajante-50-min/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 110",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/ritual-hydraluronic/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 111",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/masaje-relajante-20-min/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 112",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/viaje-a-la-ruta-de-las-delicias/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 113",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/ritual-placeres-del-mediterraneo/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 114",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/viaje-a-la-provenza/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 115",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/circuito-spa-2-adultos-1-nino/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 116",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/circuito-spa-1-adulto-1-nino/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 117",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/circuito-spa-adulto/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 118",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/circuito-spa-2-adultos-2-ninos/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 119",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/masaje-deep-tisue/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 120",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/ritual-brisa-de-ganesha/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 121",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/masaje-sunset-en-egipto/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 122",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/empieza-el-dia-con-armonia/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 123",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/masaje-descontracturante-50/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 124",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/masaje-relajante-oriental-25/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 125",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/ritual-de-oriente-50/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 126",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/bono-gastrobodyna-2-pax/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 127",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/masaje-relajante-50/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 128",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/masaje-sublime-de-la-polinesia-lomi-lomi-50/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 129",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/masaje-relajante-25/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 130",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/acceso-spa-relax-50-min/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 131",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/acceso-spa-relax-para-dos-50-min/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 132",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/bono-relajese-y-disfrute/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 133",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/bono-relajese-y-disfrute-2-pax/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 134",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-seda-club/masaje-aromatico-75/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 135",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-seda-club/masaje-aromatico-25/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 136",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-seda-club/masaje-aromatico-55/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 137",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-seda-club/hidratante-source-marino/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 138",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-seda-club/ritual-al-andalus/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 139",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-seda-club/peeling-corporal-de-coco-hidratacion-completa/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 140",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-seda-club/masaje-relajante-25/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 141",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-seda-club/masaje-relajante-75/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 142",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-seda-club/masaje-relajante-55/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 143",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-seda-club/circuito-spa-privado-pareja/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 144",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-seda-club/masaje-aceites-esenciales-25/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 145",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-seda-club/masaje-aceites-esenciales-75/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 146",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-seda-club/masaje-aceites-esenciales-55/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 147",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-seda-club/masaje-holistico/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 148",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-seda-club/masaje-de-pindas-herbales/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 149",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/health-club-ghm-monachil/masaje-lomi-lomi/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 150",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/health-club-ghm-monachil/masaje-sueco/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 151",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/health-club-ghm-monachil/masaje-relajante-55-minutos/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 152",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/health-club-ghm-monachil/full-moon-couple-experience/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 153",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/health-club-ghm-monachil/half-moon-couple-experience/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 154",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/health-club-ghm-monachil/crescent-moon-couple-experience/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 155",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/health-club-ghm-monachil/jacuzzi-privado/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 156",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/health-club-ghm-monachil/shiatsu-craneal/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 157",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/health-club-ghm-monachil/shiatsu/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 158",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/health-club-ghm-monachil/masaje-deportivo/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 159",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-hotel-el-mirador/vinoterapia-60-min/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 160",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-hotel-el-mirador/masaje-con-piedras-calientes-60-min/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 161",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-hotel-el-mirador/chocolaterapia-60-min/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 162",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-hotel-el-mirador/masaje-relajante-30-min/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 163",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-hotel-el-mirador/oferta-circuito-hidrotermal/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 164",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-hotel-el-mirador/circuito-hidrotermal/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 165",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-hotel-el-mirador/aromaterapia-30-min/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 166",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-hotel-el-mirador/masaje-con-canas-de-bambu-60-min/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 167",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-hotel-el-mirador/circuito-hidrotermal-1-botella-de-vino/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.name ?? "Service landing: 168",
    path: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.path ?? "/spa-balneario/granada/spa-hotel-el-mirador/drenaje-linfatico/",
    props: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.props ?? false,
    meta: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta || {},
    alias: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.alias || [],
    redirect: ServiceDetail_46pageS7RbNb_fhnSQuljrIkhUXiK1sltyepq8MDIvD9TlvFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-7912fad4-8c0f-461a-8ed5-0591f8a778aa/b2c-build/core/pages/service/ServiceDetail.page.vue")
  }
]